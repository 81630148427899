.rightWeatherContainer {
  margin-right: 2.7%;
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.textContainer {
  margin: 1% 0;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.icon {
  margin-left: 10px;
  margin-top: 3px;
}

.favorites {
  display: flex;
}

.favoriteContainer {
  margin-top: 1%;
}

.searchBoxContainer {
  min-width: 200px;
}
