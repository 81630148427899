.todayContainer {
  margin-top: 2%;
  display: flex;
  width: 100%;
}

.wrapperContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #2a2951;
  flex: 5;
}

.rightToTheWrapper {
  background: #131231;
}

.container {
  display: flex;
  width: 100%;
  min-width: 1050px;
}

.leftWeatherContainer {
  margin-right: 2.7%;
  flex: 2;
  white-space: nowrap;
  min-width: 500px;
}

.topContainer {
  padding-left: 20px;
  flex: 1;
  display: flex;
  height: 25%;
}

.bottomContainer {
  height: 100%;
}

.additionalContainer {
  height: 100%;
  width: 280px;
}
