.mapContainer {
  height: calc(88% - 65px);
  width: 100%;
  position: absolute;
  z-index: 10;
  top: 12%;
}

.container {
  height: 100%;
  width: 100%;
  position: relative;
}

.favoriteContainer {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 12%;
}

.menuButton {
  display: flex;
  position: absolute;
  background: #181837;
  bottom: 10%;
  z-index: 20;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.menuButton a:last-child,
.menuButton a:last-child > button {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.slider {
  background: #181837;
  height: 65px;
  width: 100%;
  padding: 0 2%;
  position: absolute;
  bottom: 0;
  z-index: 20;
}
