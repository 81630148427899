.container {
  flex-basis: 100%;
  min-height: 280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 10px 20px 30px;
  background: #18163d;
  border-radius: 5px;
  position: relative;
}

.cityCountryContainer {
  display: flex;
  width: 70%;
  margin-bottom: 8px;
}

.imageContainer {
  width: 20vh;
  position: absolute;
  right: -10px;
  top: -20px;
}

.imageResponsive {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.todayWeatherContainer {
  display: flex;
  margin-top: 1%;
  flex: 1;
  align-items: center;
}

.todayWeatherIconsContainer {
  background: #22234d;
  padding: 2px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.todayWeatherSubContainer {
  display: flex;
  height: 100%;
}

.todayWeatherTextContainer {
  margin-left: 10%;
  padding-right: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.todayWeatherTextContainer div {
  margin-bottom: 6px;
  margin-top: 6px;
}

.todayWeatherSubContainer:last-child .todayWeatherTextContainer {
  max-width: 70px;
  width: auto;
  min-width: 30px;
}

.todayWeatherIconsContainer svg {
  /* fill: #353666; */
}

.todayWeatherIconsContainer svg path {
  /* fill: #44448a; */
  stroke: #44448a;
}

.circularProgressContainer {
  margin-right: 4%;
}

.forecastContainer {
  display: flex;
  margin-top: 20px;
}

.forecastContainer > div {
  margin-right: 10px;
}
