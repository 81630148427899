.windIconContainer {
  margin-right: 10px;
}

.windContainer {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}

.windContainer:hover {
  cursor: pointer;
  background: #3a3966;
}

.textContainer {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.windContainerFavorite {
  flex-direction: column;
}

.windIconContainerNotFavorite {
  padding-left: 13px;
}

.windContainerFavorite:hover {
  cursor: default;
  background: none;
}

.textContainerFavorite {
  margin-left: 0;
  margin-top: 10px;
}
