.container {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* .container > div {
  flex: 0 0 auto;
  margin: 15px;
} */
