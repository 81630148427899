.autoCompleteContainer {
  background: white;
  border: 1px solid grey;
  max-height: 300px;
  overflow: auto;
}

.spinner {
  position: absolute;
  top: 11px;
  right: 20px;
  width: 20px;
  margin-left: auto;
  z-index: 10;
}

.searchIcon {
  position: absolute;
  top: 9px;
  left: 16px;
}

.subContainer {
  position: relative;
}
