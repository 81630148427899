.container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 14%;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
