.container {
  width: 16vh;
  background: #131231;
  padding: 8px 12px;
  border-radius: 10px;
}

.container:hover {
  cursor: pointer;
}

/* .container:not(:last-child) {
  margin-right: 13px;
} */

.timeContainer {
  display: flex;
  justify-content: space-between;
}

.temperatureContainer {
  display: flex;
}

.imageContainer {
  max-width: 56px;
  height: 5vh;
  max-height: 54px;
}

.imageResponsive {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
