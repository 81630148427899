.textField {
  margin-bottom: 15px;
}

.button {
  display: flex;
  justify-content: center;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  width: 225px;
}
