.weatherInfoContainer {
  padding: 10px 0;
  padding-left: 35px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 100%;
}

.weatherInfoContainer:hover {
  cursor: pointer;
  background: #3a3966;
}

.circularProgressContainer {
  position: relative;
}

.centerNumber {
  left: 21px;
  top: 21px;
  position: absolute;
}

.weatherIconContainer {
  margin-right: 10px;
}

.textContainer {
  display: flex;
  align-items: center;
}

.marginLeft {
  margin-left: 32px;
}

.marginTop {
  margin-top: 15px;
}

.weatherInfoContainerFavorite {
  flex-direction: column;
  padding: 0;
}

.weatherInfoContainerFavorite:hover {
  background: none;
  cursor: default;
}

.textContainerFavorite {
  margin-left: 0;
  margin-top: 10px;
}
