.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  margin: 0 auto;
  padding: 20px;
}

.defaultViewContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
