.container {
  position: relative;
}

.northContainer {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
}

.southContainer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.estContainer {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 6px;
}

.westContainer {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
}

.circularProgress {
  position: absolute;
}
