.container {
  position: relative;
}

.svg {
  transform: rotate(-90deg);
}

.svgMirror {
  transform: rotate(90deg) scale(-1, 1);
}

.textContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}

.biggerFont {
  font-size: 1.5rem;
}
