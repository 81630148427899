.container {
  flex-basis: 100%;
  height: 80px;
  background: #22234d;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.imageContainer {
  max-width: 26px;
  display: flex;
  flex-direction: column;
  margin: 3px 0;
}

.imageResponsive {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
