.mainInfoContainer {
  background-color: rgba(42, 41, 81, 0.75);
  border-radius: 20px;
  /* padding: 1.7% 0px 1% 2%; */
  display: flex;
}

.mainInfoContainerOverlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.imageResponsive {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.infoContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  position: relative;
  width: 55vh;
}

.locationContainer {
  margin-top: 1.7%;
}

.temperatureContainer {
  display: flex;
  align-items: center;
  margin-top: 2%;
  margin-bottom: auto;
}

.icon {
  margin-left: 35px;
}

.descriptionContainer {
  margin-top: 2.3%;
}

.feelsLikeContainer {
  margin-top: 1.7%;
}

.weatherContainer {
  display: flex;
  flex-grow: 1;
  padding-top: 48px;
  padding-right: 20px;
}

.mainContainer {
  display: flex;
  flex: 3;
}

.imageContainer {
  margin-top: -10px;
  height: 15vh;
  max-width: 25vh;
  margin-top: 1.5vh;
  /* max-width: 200px;
  max-height: 165px; */
}
