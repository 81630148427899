.weatherInfoContainer {
  padding-bottom: 20px;
}

.sunInfoContainer {
  display: flex;
  justify-content: flex-start;
}

.sunInfoMiniContainer {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.sunInfoMiniContainer:first-child {
  margin-left: 0;
}

.sunText:first-child {
  margin-left: 3px;
}
