.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  height: 72%;
}

.forecastContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5%;
}

.chartContainer {
  position: relative;
  height: 100%;
  margin-top: 1%;
}

.chartContainerWrapper {
  position: absolute;
  top: 0;
  height: 110%;
  width: 100%;
}

.chartTitleContainer {
  margin-top: 3%;
  margin-bottom: 1%;
}

.sliderContainer {
  margin-right: 35px;
  margin-left: 20px;
}

.textContainer {
  margin-left: 8px;
  margin-bottom: 2%;
}

.todayContainer {
  margin-top: 2%;
  display: flex;
  width: 100%;
}

.topContainer {
  padding-left: 20px;
  flex: 1;
  display: flex;
  height: 23%;
  margin-top: 2%;
}
