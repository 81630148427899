.mainContainer {
  padding-top: 1.5%;
  padding-bottom: 1%;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  background: #3a3966;
  border-radius: 18px;
  margin: 0 5px;
}

.highlight {
  background: #6c66fa;
}

.weatherContainer {
  display: flex;
  flex-direction: column;
}

.iconContainer {
  display: flex;
  justify-content: center;
  margin: 6% 0;
}

.dayContainer {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.nightContainer {
  display: flex;
  align-items: baseline;
  justify-content: center;
}
