.selectContainer {
  width: 100px;
  margin-bottom: 40px;
}

.container {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chartContainer {
  padding: 10px;
  background: #18163d;
  border-radius: 5px;
  height: 100%;
  position: relative;
}

.chartTitleText {
  width: calc(100% - 20px);
  position: absolute;
  display: flex;
  justify-content: center;
}

.weatherInfo {
  background: none;
}

.imageContainer {
  width: 15vh;
}

.imageResponsive {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
