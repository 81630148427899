.airGauge {
  margin-left: 25px;
  display: flex;
  margin-top: 25px;
  align-items: center;
  height: 60px;
}

.airGauge:hover {
  cursor: pointer;
  background: #f7f7f7;
}

.typoAirGauge {
  margin-left: 30px !important;
  margin-bottom: 25px !important;
}

.mainContainer {
  max-width: 250px;
  padding-left: 0;
}

.otherContainer {
  background: #131231;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.otherContainer > div {
  border-top: 2px solid #222145;
}

.otherContainer > div:focus {
  outline: none;
}
