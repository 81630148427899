.container {
  display: flex;
  align-items: center;
  height: 100%;
  background: #131231;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.55);
}

.container > div {
  border-right: 2px solid #202043;
  height: 100%;
}

.countryTemp {
  width: 16%;
  padding-left: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.temperature {
  display: flex;
  align-items: center;
  justify-content: center;
}

.temperatureIcon {
  margin-right: 1%;
}

.graphTemp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  border-right: none !important;
}

.weatherInfo {
  width: 30%;
  border-left: 2px solid #202043;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.subWeatherInfo {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  overflow: hidden;
  max-height: 65px;
  width: 100%;
}

.subWeatherInfo > div {
  margin: 0 5px;
}

.graph {
  width: 26%;
  margin-top: 1.5%;
  border-right: none !important;
}

.airGraph {
  width: 10%;
  border-left: 2px solid #202043;
}

.controlButtons {
  width: 8%;
  padding-top: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1280px) {
  .subWeatherInfo {
    max-height: 55px;
  }
}
