#customButtons {
  display: none;
}

#cd-zoom-in,
#cd-zoom-out {
  height: 32px;
  width: 32px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 20px;
  background-color: #131231;
  background-repeat: no-repeat;
  background-size: 32px 64px;
  background-image: url('../../svgs/cdIconController.svg');
}

#cd-zoom-in:hover,
#cd-zoom-out:hover,
#cd-map:hover,
#cd-satellite:hover,
#cd-quick-access:hover {
  background-color: #212056;
}

#cd-zoom-in {
  background-position: 50% 0;
  border-bottom: 1px solid #1f1e42;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

#cd-zoom-out {
  background-position: 50% -32px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 10px;
}

#cd-map {
  background: #131231;
  margin-left: 20px;
  border-right: 1px solid #1f1e42;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

#cd-map,
#cd-satellite {
  margin-top: 10px;
  display: inline-block;
  padding: 8px 17px;
  font-size: 18px;
  cursor: pointer;
}

#cd-quick-access {
  background-color: #131231;
  margin-top: 10px;
  margin-right: 10px;
  display: inline-block;
  padding: 8px 17px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 8px;
}

#cd-satellite {
  background-color: #212056;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
